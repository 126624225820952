// JavaScript Document

//ページトップ

$(function() {
    var topBtn = $('#pagetop');    
    topBtn.hide();
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
    //スクロールしてトップ
    topBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});

//グローバルナビの開閉

$(function() {
  // ボタンクリックで開く
    $("#gnavi-school").on("click", function() {
        $("#gnavi-school-nav").slideToggle();
    });


});


//追尾ナビゲーション

$(function() {
    var topBtn = $('#scrollmenu');    
    topBtn.hide();
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
  // ボタンクリックで開く
    $("#scrollmenu-button").on("click", function() {
        $(".scrollmenu-nav nav").slideToggle();
        $(this).toggleClass('scrollmenu-button-open');
    });


});

//スマホだけ電話
$(function() {
    if (!isPhone())
        return;

    $('span[data-action=call]').each(function() {
        var $ele = $(this);
        $ele.wrap('<a href="tel:' + $ele.data('tel') + '"></a>');
    });
});

function isPhone() {
    return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0);
}

//ヘッダー固定分アンカーずらす
$(function () {
    var headerHight = 80; //ヘッダの高さ
    $('a[href^=#]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top-headerHight; //ヘッダの高さ分位置をずらす
        $("html, body").animate({scrollTop:position}, 550, "swing");
        return false;
    });
});


//高さ揃え

$(function() {
    $('.mh').matchHeight();
});



//お客様の声開閉

$(function() {
    $(".scool-item-voice-morebtn01").on("click", function() {
        $(".scool-item-voice-more01").slideToggle();
    });
    $(".scool-item-voice-morebtn02").on("click", function() {
        $(".scool-item-voice-more02").slideToggle();
    });
    $(".scool-item-voice-morebtn03").on("click", function() {
        $(".scool-item-voice-more03").slideToggle();
    });


});
